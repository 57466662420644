import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Image } from "@atoms/Image"
import { TransitionLink } from "@atoms/Link"
// import { FluidImage } from "@atoms/Image"
// import { useObserver } from "mobx-react-lite"
// import { useStore } from '@hooks/useStore'
import { footerMenuData, logoutHandler} from "@utility/UrlData"
import { Cookies } from "react-cookie"
import * as css from "@css/layouts/l-footer.module.styl"

import { isLoggedIn } from "@src/services/auth.js"

export default function Footer()
{ 
    const q = useStaticQuery(graphql`
        query {
            card_white : file(relativePath: { eq: "Common__img__card-white.png" }) { childImageSharp {
                ...Card
            } }
            logo : file(relativePath: { eq: "Common__logo__love-peace-2.svg" }) {
                publicURL
            }
        }
    `)
    let cookies = new Cookies()
    return(
        <footer className={css.lFooter}>
            <div className={css.lFooter__inner}>
                <div className={css.lFooter__logo}>
                    <img src={q.logo.publicURL} alt="YU SHIROTA OFFICIAL FANCLUB LOVE&PEACE" />
                </div>
                <nav className={css.pFooterMenu}>
                    <ul>
                        {footerMenuData.map( (val, i) => {
                            return(
                                <li key={i}>
                                    <TransitionLink to={val[1]}>{val[0]}</TransitionLink>
                                </li>
                            )
                        })}
                        {isLoggedIn() &&
                            <li onClick={logoutHandler}>
                                <a>ログアウト</a>
                            </li>
                        }
                    </ul>
                </nav>
                <div className={css.lFooter__copyright}>
                    © {new Date().getFullYear()} LIVE IN PEACE. All Rights Reserved.
                </div>
            </div>
        </footer>
    )
}