import React, { useState } from "react"
// import { graphql , useStaticQuery} from "gatsby"
// import { globalHistory } from '@reach/router'
import { useRecoilState } from 'recoil'
import { userLoginState } from "@status/UserState"
import { PageState } from '@status/PageState'
// import { navigate } from 'gatsby-link'

import { motion, AnimatePresence } from 'framer-motion'
import { useEventListener } from '@hooks/useEventListener'
import useVh from "@hooks/useVh"
import { SmoothScroll } from "@hooks/useSmoothScroll"

// import ThreeJs from '@components/Three/Main.js'
// import Cursor from '@components/Cursor'
import Header from '@layouts/Header'
import Footer from "@layouts/Footer"
import Loading from "@layouts/Loading"
import ModalMenu from "@layouts/ModalMenu"
// import { useObserver } from "mobx-react-lite"
// import { useStore } from '@hooks/useStore'
import { checkLogin, isBrowser, logout } from "@src/services/auth.js"
import { menuData, loginHandler, clickLoginHandler, registerHandler, logoutHandler } from "@utility/UrlData"

import '@css/style.styl'

export const TransitionContext = React.createContext({
    transitionState: 0,
    setTranstionState: ()=>{},
    duration: 0,
    delay: 0,
})

const options = {
    duration: 1.6,
    delay: 0.3,
}

export const useTransition = () => React.useContext(TransitionContext)

export function TransitionLayout({ children, location })
{
    const [userLogin, setUserLogin] = useRecoilState(userLoginState)
    const [pageState, setPageState] = useRecoilState(PageState)
    const [checked, setChecked] = React.useState(false)
    const [transitionState, setTranstionState] = React.useState(3)
    const key = location.pathname
    const duration = 0
    const delay = 0
    useVh()
    // console.log(location)

    const params = {
        initial: {
            position: 'relative',
            // opacity: 1,
        },
        enter: {
            transition: {
                duration: duration,
                delay: delay,
                when: 'beforeChildren',
            },
            // opacity: 1,
        },
        exit: {
            transition: { duration: duration },
            // opacity: 1,
        },
    }
    

    React.useEffect(() => {
        // 0: none, 1:start, 2:loading, 3: complete
        //start with TransitionLink.
        console.log(transitionState)
        //init
        if (transitionState === 1) {
            console.log('[[[ Transition <<< Show Start >>> ]]]')
        } else if (transitionState === 2) {
            console.log('[[[ Transition <<< Loading Start >>> ]]]')
            setPageState({isHideHeader: false})
        } else if (transitionState === 3) {
            console.log('[[[ Transition <<< Loading Complete >>> ]]]')
            if( checked ){
                setTranstionState(4)
            }
        } else if (transitionState === 4) {
            console.log('[[[ Transition <<< Hide Start >>> ]]]')
            setTimeout(() => {
                setTranstionState(0)
            }, (options.duration + options.delay) * 1000)
        }
    }, [checked, transitionState])

    //browser back push action
    useEventListener('popstate', () => {
        setTranstionState(0)
    })

    React.useLayoutEffect(() => {
        if (location.pathname === "/error/"){
            setChecked(-3)
            return
        }
        if (location.pathname === "/404/" || location.pathname === "/500/") {
            setChecked(-3)
            return
        }
        checkLogin({
            successCallback: () => {
                if (location.pathname === "/") {
                    if (isBrowser()) {
                        window.location.href = "/member/"
                    }
                }
                setChecked(1)
                setUserLogin(true)
            },
            cancelCallback: () => {
                setChecked(-1)
                // if (isBrowser()) {
                //     if (location.pathname !== "/404/") {
                //         window.location.href = "/404/"
                //     }
                // }
            },
            errorCallback: (error) => {
                console.log("api error",error)
                setChecked(-2)
                logout({callback: ()=>{}})
                if (isBrowser()) {
                    if( error === "Error: 999" || error === "Error: -1" || error === "Error: -2"){
                        window.location.href = "/500/"
                    } else
                    if (error === "Error: 110" || error === "Error: 120" || error === "Error: 121"){
                        console.log("api error", 'dead api server')
                        window.location.href = "/500/"
                    } else {
                        clickLoginHandler()
                    }
                }
            }
        })
    }, [])

    React.useEffect(() => {
        if ( isBrowser() ){
            console.log(window.location)
            let hash = window.location.hash
            if(hash !== ""){
                SmoothScroll(hash)
            }
        }
    },[])

    // const [isHideMode, setIsHideMode] = useState(false)
    // React.useEffect(()=>{
    //     if( pageState.isHideHeader ){
    //         setIsHideMode(true)
    //     } else {
    //         setIsHideMode(false)
    //     }

    // })

    return (
        <>
            <TransitionContext.Provider value={{
                transitionState: transitionState,
                setTranstionState: setTranstionState,
                duration: !pageState.isHideHeader ? options.duration : 0,
                delay: !pageState.isHideHeader ? options.delay : 0,
            }}>
                <div id="root-container" className="root-container">
                    { !pageState.isHideHeader && <Loading /> }
                    { !pageState.isHideHeader && <Header/> }
                    { userLogin && <ModalMenu/> }
                    <AnimatePresence
                        onExitComplete={() => {
                            if (transitionState === 1) {
                                setTranstionState(2)
                            }
                        }}
                        exitBeforeEnter
                    >
                        <motion.div
                            key={key}
                            variants={params}
                            initial="initial"
                            animate="enter"
                            exit="exit"
                            onAnimationStart={() => {
                            }}
                            onAnimationComplete={() => {
                                if (transitionState === 2) {
                                    setTranstionState(3)
                                }
                            }}
                        >
                            {children}
                            { !pageState.isHideHeader && <Footer /> }
                        </motion.div>
                    </AnimatePresence>
                </div>
            </TransitionContext.Provider>
        </>
    )
}
