import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
// import { globalHistory } from '@reach/router'
import { gsap, Power4 } from 'gsap'

import * as Anim from "@atoms/animation/Animation"
import { TransitionLink } from "@atoms/Link"
import { menuData, footerMenuData, logoutHandler} from '@utility/UrlData'
import { ModalMenuState } from '@status/ModalMenuState'
import { useRecoilState } from "recoil"

import * as css from "@css/layouts/l-modal-menu.module.styl"

export default function ModalMenu()
{
    // const { uiStore } = useRootStore()
    // console.log(uiStore)
    // console.log('store', store)
    // console.log('store', store.hoge)
    let size = 40
    let borderWidth = 2
    let borderMargin = 9
    let borderTopPosition = size/2-borderWidth/2-borderMargin/2
    let borderBottomPosition = size/2-borderWidth/2+borderMargin/2

    const [isOpen, setIsOpen] = useRecoilState(ModalMenuState)
    const [isRuning, setIsRuning] = React.useState(false)

    let filColor = ["#ffffff","#ffffff"]

    const buttonRef = React.useRef()
    const topBorderRef = React.useRef()
    const bottomBorderRef = React.useRef() 
    const menuRef = React.useRef()
    const bgRef = React.useRef()
    const q = useStaticQuery(graphql`
        query {
            menu : file(relativePath: { eq: "Header__btn__menu.svg" }) { publicURL }
        }
    `)

    //0: closed 1:opend 2: transing

    // React.useEffect(()=>{
    //     appStore.modalMenu = isOpen
    // },[isOpen])

    React.useEffect(() =>{

        const onComplate = () => {
            setIsRuning(false)
        }

        if ( isOpen === 0){
            let topTl = gsap.timeline()
            topTl
                .set(topBorderRef.current, {
                    rotate: '-135deg',
                    x: 33,
                    y: 22,
                })
                .to(topBorderRef.current, {
                    duration: 0.3,
                    ease: Power4.easeInOut,
                    fill: filColor[1],
                    scaleX: 0,
                })
                .set(topBorderRef.current, {
                    rotate: '0deg',
                    x: 0,
                    y: 0,
                })
                .to(topBorderRef.current, {
                    duration: 0.3,
                    ease: Power4.easeInOut,
                    fill: filColor[1],
                    scaleX: 1.0,
                })

            let borderTl = gsap.timeline()
            borderTl
                .set(bottomBorderRef.current, {
                    rotate: '135deg',
                    x: 34,
                    y: -17,
                })
                .to(bottomBorderRef.current, {
                    delay: 0.15,
                    duration: 0.3,
                    ease: Power4.easeInOut,
                    fill: filColor[1],
                    scaleX: 0,
                })
                .set(bottomBorderRef.current, {
                    rotate: '0deg',
                    x: 0,
                    y: 0,
                })
                .to(bottomBorderRef.current, {
                    duration: 0.3,
                    ease: Power4.easeInOut,
                    fill: filColor[1],
                    scaleX: 1.0,
                })

            let menuTl = gsap.timeline()
            menuTl
                .to(menuRef.current, {
                    duration: 1.2,
                    ease: Power4.easeInOut,
                })
                .set(menuRef.current,{
                    display: 'none',
                })
                .to(bgRef.current, {
                    ease: Power4.easeIn,
                    duration: 0.4,
                    scaleY: 0.1,
                })
                .to(bgRef.current, {
                    ease: Power4.easeOut,
                    duration: 0.4,
                    scaleY: 0.0,
                    scaleX: 0.0,
                    onComplete: onComplate,
                })
        } else if( isOpen === 1 ) {
            let topTl = gsap.timeline()
            topTl
            .to(topBorderRef.current, {
                duration: 0.3,
                ease: Power4.easeInOut,
                fill: filColor[1],
                scaleX: 0,
                x: size,
            })
            .set(topBorderRef.current, {
                rotate: '45deg',
                x: 6,
                y: -8,
            })
            .to(topBorderRef.current, {
                duration: 0.3,
                ease: Power4.easeInOut,
                fill: filColor[1],
                scaleX: 1.0,
            })

            let borderTl = gsap.timeline()
            borderTl
            .to(bottomBorderRef.current, {
                delay: 0.15,
                duration: 0.3,
                ease: Power4.easeInOut,
                fill: filColor[1],
                scaleX: 0,
                x: 40,
            })
            .set(bottomBorderRef.current, {
                rotate: '-45deg',
                x: 4,
                y: 10,
            })
            .to(bottomBorderRef.current, {
                duration: 0.3,
                ease: Power4.easeInOut,
                fill: filColor[1],
                scaleX: 1.0,
            })
            let menuTl = gsap.timeline()
            menuTl
                .set(menuRef.current, {
                    display: 'flex',
                })
                // .set(bgRef.current, {
                //     // borderRadius: `1000 0 1000 1000`
                // })
                .to(bgRef.current,{
                    ease: Power4.easeIn,
                    duration: 0.4,
                    scaleX: 1.0,
                    scaleY: 0.1,
                    // borderRadius: `0 0 1000 1000`
                })
                .to(bgRef.current, {
                    ease: Power4.easeOut,
                    duration: 0.4,
                    scaleY: 1.0,
                    // borderRadius: `0 0 0 0 `,
                    onComplete: onComplate
                })
                // let lis = menuRef.current.querySelectorAll('li')
                // for(const item of lis){
                //     console.log(item)
                // }
                
        }
    },[isOpen, isRuning.current])

    React.useEffect(()=>{
        console.log('update...')
    })

    const menuHandler = ()=>{
        if (isRuning === true) { return }
        if (isOpen === 0 || isOpen === -1) {
            setIsRuning(true)
            setIsOpen(1)
        } else if(isOpen === 1){
            setIsRuning(true)
            setIsOpen(0)
        }
    }

    return (
        <React.StrictMode>
            <div className={css.lModalMenuBg} ref={bgRef}></div>
            <button className={css.lModalMenuButton} onClick={menuHandler} ref={buttonRef}>
                {/* <Svg src={q.menu.publicURL} /> */}
                <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
                    <g id="Header__btn__menu" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                        <rect ref={topBorderRef} width="40" height={borderWidth} x="0" y={borderTopPosition} fill={filColor[0]} />
                        <rect ref={bottomBorderRef} width="40" height={borderWidth} x="0" y={borderBottomPosition} fill={filColor[0]} />
                    </g>
                </svg>
            </button>
            <nav className={css.lModalMenu}
                style={{display: 'none' }}
                ref={menuRef}
            >
                <div className={css.lModalMenu__inner}>
                    <div className={css.lModalMenu__list}>
                        <Anim.Event start={isOpen === 1 ? true : false} type="ModalMenu" delay={0.5}>
                        <ul>
                            {menuData.map(( arr, i) => {
                                return (
                                    <li key={i} className={css.lModalMenu__list__item}>
                                        <TransitionLink to={arr[2]} onClick={menuHandler}>
                                            <div className={css.aInner}>
                                                <p className={css.aTitle1}>{arr[0]}</p>
                                                <p className={css.aTitle2}>{arr[1]}</p>
                                            </div>
                                        </TransitionLink>
                                    </li>
                                )
                            })}
                            <li className={css.lModalMenu__list__item}>
                                <a onClick={()=>{
                                    logoutHandler({cb:()=>{
                                        menuHandler()
                                    }})
                                }}>
                                    <div className={css.aInner}>
                                        <p className={css.aTitle1}>Logout</p>
                                        <p className={css.aTitle2}>ログアウト</p>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        </Anim.Event>
                    </div>
                    <div className={css.lModalMenu__list2}>
                        <Anim.Event start={isOpen === 1 ? true : false} type="ModalMenu2" delay={0.5}>
                        <ul>
                            {footerMenuData.map((val, i) => {
                                return (
                                    <li key={i} onClick={menuHandler}>
                                        <TransitionLink to={val[1]}>{val[0]}</TransitionLink>
                                    </li>
                                )
                            })}
                        </ul>
                        </Anim.Event>
                    </div>
                    {/* <Anim.Event className={css.lModalMenu__footer} start={isOpen === 1 ? true : false} type="Fade">
                        <footer>
                                <LinkList className={css.lModalMenu__footer__menu} data={footerMenuData} />
                                <p className={css.lModalMenu__footer__copyright}>
                                    ©2020 xxxx inc.
                                </p>
                        </footer>
                    </Anim.Event> */}
                </div>
            </nav>
            
        </React.StrictMode>
    )
}
