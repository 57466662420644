import React, { useEffect } from "react"
import { gsap, Power4, Elastic } from "gsap"
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import　* as css from "@css/atoms/a-animation/index.module.styl"
gsap.registerPlugin(ScrollTrigger)

let defalutName = (type) => css[`aAnimation${type}`]
let activeName = (type) => css[`aAnimation${type}Active`]
let deactiveName = (type) => css[`aAnimation${type}Deactive`]
// let reverseName = (type) => css[`aAnimation${type}Reverse`]

export const Css = css
export const Item = ({children}) =>
{
    return <div className={css.item}>{children}</div>
}
export const ToggleClass = ({ children, className = "", type = "Fade", delay, ...props}) =>
{
    const ref = React.useRef()

    useEffect(() => {
        gsap.set(ref.current, {
            delay: delay,
            className: `${className} ${defalutName(type)} ${activeName(type)}`
        })
    },[])

    return (
        <div ref={ref} className={`${className} ${defalutName(type)}`} {...props}>
            { children}
        </div>
    )
}


export const Event = ({ children, className = "", type = "Fade", delay = 0, start,  ...props }) =>
{
    const ref = React.useRef()
    // const [fire, setFire] = React.useState(false)

    React.useEffect(()=>{
        setTimeout(()=>{
            if(start){
                ref.current.classList.remove(deactiveName(type))
                ref.current.classList.add(activeName(type))
            } else {
                ref.current.classList.remove(activeName(type))
                ref.current.classList.add(deactiveName(type))
            }
        },delay * 1000)
    },[start])

    return (
        <div ref={ref} className={`${className} ${defalutName(type)}`} {...props}>
            { children}
        </div>
    )
}

