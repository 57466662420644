// extracted by mini-css-extract-plugin
export var pHeaderMenuLogged = "tg";
export var lHeader__menuLogged = "ug";
export var aLogoLovePeace1 = "vg";
export var aLogoLovePeace2 = "wg";
export var aButton1 = "xg";
export var aButton2 = "yg";
export var aButton3 = "zg";
export var aButton4 = "Ag";
export var aButton5 = "Bg";
export var aButtonHeaderLogin = "Cg";
export var aButtonHeaderRegister = "Dg";
export var aButtonBig = "Eg";
export var aButtonRegister = "Fg";
export var aButtonLogin = "Gg";
export var aButtonContinuation = "Hg";
export var icon = "Ig";
export var aButtonHeaderSpLogin = "Jg";
export var pHeaderMenu = "Kg";
export var pHeaderMenu__item = "Lg";
export var pHeaderMenu__itemSp = "Mg";
export var pHeaderMenu__itemPc = "Ng";
export var lHeader = "Og";
export var lHeader__inner = "Pg";
export var lHeader__right = "Qg";
export var lHeader__left = "Rg";
export var animationMarquee = "Sg";