// extracted by mini-css-extract-plugin
export var aButton1 = "Tg";
export var aButton2 = "Ug";
export var aButton3 = "Vg";
export var aButton4 = "Wg";
export var aButton5 = "Xg";
export var aButtonHeaderLogin = "Yg";
export var aButtonHeaderRegister = "Zg";
export var aButtonBig = "_g";
export var aButtonRegister = "ah";
export var aButtonLogin = "bh";
export var aButtonContinuation = "ch";
export var icon = "dh";
export var aButtonHeaderSpLogin = "eh";
export var pFooterMenu = "fh";
export var lFooter = "gh";
export var lFooter__inner = "hh";
export var lFooter__logo = "ih";
export var lFooter__copyright = "jh";
export var animationMarquee = "kh";