import React from "react"
import { graphql, useStaticQuery } from "gatsby"  
// import { globalHistory, navigate } from '@reach/router'
import { useRecoilState } from 'recoil'
import { userLoginState } from "@status/UserState"
import { globalHistory } from '@reach/router'

import { TransitionLink } from "@atoms/Link"
// import { MinScreen } from "@hooks/useMediaQuery"
import { menuData, loginHandler, clickLoginHandler, registerHandler, logoutHandler} from "@utility/UrlData"
// import { useMODD } from "@hooks/useMODD"
import * as css from "@css/layouts/l-header.module.styl"
import SpLoginIcon from "@images/Common__icn__sp-login.svg"
// import Lawson from "../blocks/Lawson"

// import { isLoggedIn } from "@src/services/auth.js"

export default function Header()
{
    const [rendering,  setRendering] = React.useState(false)
    const [userLogin, setUserLogin] = useRecoilState(userLoginState)
    
    const q = useStaticQuery(graphql`
        query {
            logo : file(relativePath: { eq: "Common__logo__love-peace-1.svg" }) { publicURL }
        }
    `)
    
    React.useEffect(()=>{
    }, [userLogin])

    const LoginMenu = () => {
        const { location } = globalHistory
        if (location.pathname.match(/error/g) || location.pathname.match(/\/404($|\/$)/g) || location.pathname.match(/\/500($|\/$)/g) ){
            return null
        }
        if (userLogin ){
            return(
                <nav className={css.lHeader__menuLogged}>
                    <ul>
                        {
                            menuData.map((val, i) => {
                                return (
                                    <li key={i}><TransitionLink to={val[2]}>{val[0]}</TransitionLink></li>
                                )
                            })
                        }
                        <li onClick={logoutHandler}>
                            <a>Logout</a>
                        </li>
                    </ul>
                </nav>
            )
        } else {
            return(
                <div className={css.lHeader__menu}>
                    <nav className={css.pHeaderMenu}>
                        <ul>
                            <li className={css.pHeaderMenu__itemSp}>
                                <button className={css.aButtonHeaderSpLogin} onClick={clickLoginHandler}>
                                    <SpLoginIcon />
                                </button>
                            </li>
                            <li className={css.pHeaderMenu__itemPc}>
                                <button className={css.aButtonHeaderLogin} onClick={clickLoginHandler}>
                                    ログイン
                                </button>
                            </li>
                            <li className={css.pHeaderMenu__item}>
                                <button className={css.aButtonHeaderRegister} onClick={registerHandler}>
                                    新規登録
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            )
        }
    }
    return(
        <header className={css.lHeader} id="header">
            <div className={css.lHeader__inner}>
                <div className={css.lHeader__left}>
                    <TransitionLink className={css.aLogoLovePeace1} to={userLogin ? '/member/' : '/'}>
                        <img src={q.logo.publicURL} alt="YU SHIROTA OFFICIAL FANCLUB LOVE&PEACE"/>
                    </TransitionLink>
                </div>
                <div className={css.lHeader__right}>
                    <LoginMenu/>
                    {/* <Lawson/> */}
                </div>
            </div>
        </header>
    )
}

