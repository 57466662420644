import React from "react"
import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image"

const ImagesPath = "../../assets/images"

const ImageParams = {
    formats: ["auto", "webp"],
    layout: "fullWidth",
    aspectRatio: 3 / 1,
}

function Image({ data, alt="", ...props }) {
    const img = getImage(data)
    return (
        <GatsbyImage image={img} alt={alt} {...props} />
    )
}

export { Image, StaticImage, ImageParams, ImagesPath }