// extracted by mini-css-extract-plugin
export var lModalMenu__list__item = "Xf";
export var aTitle1 = "Yf";
export var lModalMenuBg = "Zf";
export var lModalMenuButton = "_f";
export var lModalMenu = "ag";
export var lModalMenu__inner = "bg";
export var lModalMenu__list = "cg";
export var aInner = "dg";
export var aTitle2 = "eg";
export var lModalMenu__list2 = "fg";
export var lModalMenu__footer = "gg";
export var lModalMenu__footer__menu = "hg";
export var lModalMenu__footer__copyright = "ig";
export var animationMarquee = "jg";