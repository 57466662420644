import { globalHistory, navigate } from '@reach/router'
import { logout, isBrowser } from "@src/services/auth.js"
import Cookies from 'js-cookie'

// Hook
const menuData = [
    ['News','ニュース', '/news/',],
    ['Meet','チケット情報', '/member/meet/',],
    ['Room', 'オフショット', '/member/room/',],
    ['YUtoYOU', '動画配信&生配信', '/member/yu-to-you/'],
    ['Express', 'レポート', '/member/express/'],
    ['Profile', 'プロフィール', '/member/profile/'],
    ['LP Factory', 'オンラインショップ', process.env.GATSBY_URL_FACTORY],
    ['YU Letter', 'ファンレター', '/member/yu-letter/'],
]

const footerMenuData = [
    ['会員規約', process.env.GATSBY_MODD_URL_TERM],
    ['FAQ', process.env.GATSBY_MODD_URL_HELP],
    ['お問い合わせ', process.env.GATSBY_MODD_URL_CONTACT],
    ['プライバシーポリシー', process.env.GATSBY_MODD_URL_PRIVACY],
    ['特定商取引法に基づく表示', process.env.GATSBY_MODD_URL_TOKUTEI],
]

const registerHandler = () => {
    const { location } = globalHistory
    const url = location.href.replace(/\?.*$/, "");
    if (isBrowser()) {
        window.location.href = `${process.env.GATSBY_MODD_URL_REGISTER}${url}`
    }
}

const loginHandler = () => {
    const { location } = globalHistory
    //urlパラメータが含まれていたら削除
    let url = location.href.replace(/\?.*$/, "");
    if( url.match(/404/g) || url.match(/500/g)){
        url = new URL(location.href).origin
    }
    if (isBrowser()) {
        window.location.href = `${process.env.GATSBY_MODD_URL_LOGIN}${url}`
    }
}

const clickLoginHandler = () => {
    // const cookies = Cookies.get()
    // console.log("cook->",cookies))
    Cookies.set("logined", true, {path:"/", expires: 30/86400})//3分
    const { location } = globalHistory
    //urlパラメータが含まれていたら削除
    let url = location.href.replace(/\?.*$/, "");
    if( url.match(/404/g) || url.match(/500/g)){
        url = new URL(location.href).origin
    }
    if (isBrowser()) {
        window.location.href = `${process.env.GATSBY_MODD_URL_LOGIN}${url}`
    }
}
const logoutHandler = ({cb}) => {
    Cookies.remove("logined")
    logout({
        callback: () => {
            if(cb){
                cb()
            }
            if (isBrowser()) {
                window.location.href = `${process.env.GATSBY_MODD_URL_LOGOUT}`
            }
        }
    })
}

export { menuData, footerMenuData, loginHandler, clickLoginHandler, registerHandler, logoutHandler }