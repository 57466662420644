// import React from 'react'
import { globalHistory, navigate } from '@reach/router'
// import { useRecoilState, useRecoilValue } from 'recoil'
// import { userLoginState, userInfoState } from "@status/UserState"
// import { globalHistory } from '@reach/router'
import { Cookies } from 'react-cookie'
import JsCookies from 'js-cookie'

import axios from 'axios'
import qs from 'qs';
import { escapeSpecialChars } from '@src/utility/get';
// import { getObjectSize } from '@src/utility/get';

export const isBrowser = () => typeof window !== "undefined"
const cookies = new Cookies("MyCookies")

export const getUser = () => {
    return isBrowser() && cookies.get('user', { path: '/' })
        ? cookies.get('user', { path: '/' })
        : null
}

const checkErrorCount = () => {
    const count = Number(cookies.get('error_count'))
    if(count > 2){
        cookies.set('error_count', 0, { path: '/' })
        return true
    } else {
        return false
    }
}

const validateTicket = async (ticket) => {
    
    let result
    try {
        //validat ticket
        await axios({
            credentials: true,
            method: 'POST',
            url: process.env.GATSBY_MODD_API_VALIDATE_TICKET,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify({
                ticket: `${ticket}`,
            }),
        })
        .then(({ data }) => {
            result = data
        })
        .catch(error => {
            throw 'server error'
        })
    } catch(error) {
        console.log(`Error: ${error}`)
    }
    return result
}

export const getFcUser = async (userKey) => {
    if( !userKey ){
        userKey = cookies.get('user_key', { path: '/' })
    }
    if( !userKey ) return

    let result 
    await axios({
        method: 'POST',
        url: process.env.GATSBY_MODD_API_GET_USER,
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify({
            userKey: userKey,
        }),
    })
    .then(({ data }) => {
        result = data
    })
    .catch(error => {
        throw error
    })
    return result
}

//それをログイン前にランダムパラメーターを生成する
//MODDにランダムパラメーターを渡すurl?id=xdacea
//リダイレクトURLにもid=xdaceaが付与されているのでcookieと照合する
//

export const checkLogin = async ({successCallback, cancelCallback, errorCallback}) => {
    const { location } = globalHistory
    const params = new URLSearchParams(location.search)
    let ticket = params.get('ticket', { path: '/' })
    console.log(location)
    console.log("Cookies->",JsCookies.get("logined"))

    try {
        if( ticket ){
            //url書き換え
            cookies.set('ticket', ticket, { path: '/' })
            window.history.replaceState('', '', `${location.pathname}`)
            let logined = JsCookies.get("logined")
            if( logined !== "true"　){
                //ログイン処理をしてなかった場合は不正
                throw "Error: -1"
            }
        } else {
            ticket = cookies.get('ticket', { path: '/' })
        }
        console.log(ticket)
        if (!ticket) {
            console.log('Error: 000')
            if (cancelCallback ){
                cancelCallback()
            }
            return false
        }

        if( checkErrorCount() ){
            throw `Error: 999`
        }
        //validate ticket
        const vtr = await validateTicket(ticket)
        if (!vtr ){
            throw `Error: 110`
        }
        // const { ErrCode, ErrDetail, Expiration, Expired, UserKey, Result } = vtr
        console.log('api',vtr)
        if (typeof vtr.ErrCode === 'string' ||  vtr.Result !== "1") {
            throw `[MODD API Error Code: ${vtr.ErrCode}] ${vtr.ErrDetail}`
        }
        //保存されているユーザーキーが違ったらエラー
        let ukey = cookies.get('user_key', { path: '/' })
        if( ukey ){
            if( ukey  !== vtr.UserKey ){
                throw "Error: -1"
            }
        }

        if (vtr.Expired === 'true') {
            cookies.remove('ticket', { path: '/' })
            cookies.remove('user_key', { path: '/' })
            cookies.remove('user', { path: '/' })
            throw `Error: 111`
        }

        //get user
        const gfu = await getFcUser(vtr.UserKey)
        console.log("gfu===>",gfu)
        if( !gfu ){
            throw 'Error: 120'
        }
        console.log(gfu)
        // const { FcElements, Result } = fcUser
        const FcExpansionElement = gfu.FcElements.FcExpansionElement
        if( !FcExpansionElement ){
            throw 'Error: 121'
        }
        console.log("FcExpansionElement=>",FcExpansionElement)
        const { FcStatus } = Array.isArray(FcExpansionElement) ? FcExpansionElement[0] : FcExpansionElement
        console.log("FcExpansionElement=>",FcStatus)
        // console.log('api error', gfu,FcExpansionElement)
        if (FcStatus !== "0" || gfu.Result !== "1") {
            console.log('api error', FcExpansionElement)
            throw 'Error: 122'
        }

        console.log('<<<<<< login ok >>>>>>')
        console.log("error",cookies.get('error_count'))
        let today = new Date()
        let tomorrow = new Date()
        tomorrow.setDate(today.getDate() + 1)
        cookies.set('error_count', 0, { path: '/' })
        cookies.set('user_key', vtr.UserKey, { expires: tomorrow, path: '/' })
        delete gfu.crypto
        cookies.set('user', escapeSpecialChars(gfu), { expires: tomorrow,  path: '/' })
        console.log('user=>', cookies.get('user', { path: '/' }))
        if ( successCallback ){
            successCallback()
        }
        return true
    } catch(error){
        if( errorCallback ){
            errorCallback(error)
        }
        return false
    }
}

export const isLoggedIn = () => {
    const user = getUser()
    console.log("login=>",user)
    return !!user
}
export const logout = ({callback}) => {
    //count
    let count = Number(cookies.get('error_count', { path: '/' }))
    if(!count){
        count = 0
    }
    count += 1
    cookies.set('error_count', count, { path: '/' })
    console.log("error",cookies.get('error_count'))

    cookies.remove('ticket', { path: '/' })
    cookies.remove('user_key', { path: '/' })
    cookies.remove('user', { path: '/' })
    //member
    cookies.remove('ticket', { path: '/member' })
    cookies.remove('user_key', { path: '/member' })
    cookies.remove('user', { path: '/member' })

    if (callback) {
        callback()
    }
}